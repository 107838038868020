import { useCallback, useEffect, useReducer, useState } from 'react';
import Auth0Context, { RedirectLoginOptions } from '../auth0-context';
import {
  IdToken,
  LogoutOptions,
  LogoutUrlOptions,
  PopupLoginOptions,
  PopupConfigOptions,
  RedirectLoginOptions as Auth0RedirectLoginOptions,
  GetTokenWithPopupOptions,
  GetTokenSilentlyOptions,
  GetIdTokenClaimsOptions,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js';

import { hasAuthParams, loginError, tokenError } from '../utils';
import { reducer } from '../reducer';
import { actions, authClient } from '../config-options';
import { AppState, IAuthProviderOptions, initialAuthState } from '../types';

/**
 * @ignore
 */
const defaultOnRedirectCallback = (appState?: AppState): void => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname,
  );
};

/**
 * @ignore
 */
const toAuth0LoginRedirectOptions = (
  opts?: RedirectLoginOptions,
): Auth0RedirectLoginOptions | undefined => {
  if (!opts) {
    return;
  }
  const { redirectUri, ...validOpts } = opts;
  return {
    ...validOpts,
    redirect_uri: redirectUri,
  };
};

/**
 * Provides the Auth0Context to its child components.
 */
export const AuthProvider = (opts: IAuthProviderOptions): JSX.Element => {
  const {
    children,
    skipRedirectCallback,
    onRedirectCallback = defaultOnRedirectCallback,
  } = opts;
  const [client] = useState(authClient);
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (hasAuthParams() && !skipRedirectCallback) {
          const { appState } = await client.handleRedirectCallback();
          onRedirectCallback(appState);
        } else {
          await client.checkSession();
        }
        const user = await client.getUser();
        dispatch({ type: actions.INITIALISED, user });
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          error: loginError(error as Error),
        });
      }
    })();
  }, [client, onRedirectCallback, skipRedirectCallback]);

  const buildAuthorizeUrl = useCallback(
    (opts?: RedirectLoginOptions): Promise<string> =>
      client.buildAuthorizeUrl(toAuth0LoginRedirectOptions(opts)),
    [client],
  );

  const buildLogoutUrl = useCallback(
    (opts?: LogoutUrlOptions): string => client.buildLogoutUrl(opts),
    [client],
  );

  const loginWithRedirect = useCallback(
    (opts?: RedirectLoginOptions): Promise<void> =>
      client.loginWithRedirect(toAuth0LoginRedirectOptions(opts)),
    [client],
  );

  const loginWithPopup = useCallback(
    async (options?: PopupLoginOptions, config?: PopupConfigOptions): Promise<void> => {
      dispatch({ type: actions.LOGIN_POPUP_STARTED });
      try {
        await client.loginWithPopup(options, config);
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          error: loginError(error as Error),
        });
        return;
      }
      const user = await client.getUser();
      dispatch({ type: actions.LOGIN_POPUP_COMPLETE, user });
    },
    [client],
  );

  const logout = useCallback(
    (opts: LogoutOptions = {}): void => {
      client.logout(opts);
      if (opts.localOnly) {
        dispatch({ type: actions.LOGOUT });
      }
    },
    [client],
  );

  const getAccessTokenSilently = useCallback(
    async (opts?: GetTokenSilentlyOptions): Promise<string> => {
      let token;
      try {
        token = await client.getTokenSilently(opts);
      } catch (error) {
        throw tokenError(error as Error);
      } finally {
        dispatch({
          type: actions.GET_ACCESS_TOKEN_COMPLETE,
          user: await client.getUser(),
        });
      }
      return token;
    },
    [client],
  );

  const getAccessTokenWithPopup = useCallback(
    async (
      opts?: GetTokenWithPopupOptions,
      config?: PopupConfigOptions,
    ): Promise<string> => {
      let token;
      try {
        token = await client.getTokenWithPopup(opts, config);
      } catch (error) {
        throw tokenError(error as Error);
      } finally {
        dispatch({
          type: actions.GET_ACCESS_TOKEN_COMPLETE,
          user: await client.getUser(),
        });
      }
      return token;
    },
    [client],
  );

  const getIdTokenClaims = useCallback(
    (opts?: GetIdTokenClaimsOptions): Promise<IdToken> =>
      client.getIdTokenClaims(opts) as Promise<IdToken>,
    [client],
  );

  const handleRedirectCallback = useCallback(
    async (url?: string): Promise<RedirectLoginResult> => {
      try {
        return await client.handleRedirectCallback(url);
      } catch (error) {
        throw tokenError(error as Error);
      } finally {
        dispatch({
          type: actions.HANDLE_REDIRECT_COMPLETE,
          user: await client.getUser(),
        });
      }
    },
    [client],
  );

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        buildAuthorizeUrl,
        buildLogoutUrl,
        getAccessTokenSilently,
        getAccessTokenWithPopup,
        getIdTokenClaims,
        loginWithRedirect,
        loginWithPopup,
        logout,
        handleRedirectCallback,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
