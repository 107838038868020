import * as ReactDOM from 'react-dom';
import './global.scss';

import App from './app/app';
import { AuthProvider } from '@srm-pp/authentication';
import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE_KEY as string);

ReactDOM.render(
  <AuthProvider>
    <CssBaseline />
    <App />
  </AuthProvider>,
  document.getElementById('root'),
);
