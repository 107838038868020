import { Auth0ClientOptions, Auth0Client } from '@auth0/auth0-spa-js';

// environment for the Auth provider
export const authClientOptions: Auth0ClientOptions = {
  domain: process.env.NX_AUTH_DOMAIN as string,
  client_id: process.env.NX_AUTH_CLIENT_ID as string,
  audience: process.env.NX_AUTH_AUDIENCE as string,
  scope: process.env.NX_AUTH_SCOPES as string,
  rolesClaimName: process.env.NX_AUTH_ROLES_CLAIM_NAME as string,
  redirect_uri: `${window.location.origin}/auth/callback`,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
};

export const authClient = new Auth0Client(authClientOptions);

export enum actions {
  LOGIN_POPUP_STARTED = 'authentication/LOGIN_POPUP_STARTED',
  LOGIN_POPUP_COMPLETE = 'authentication/LOGIN_POPUP_COMPLETE',
  INITIALISED = 'authentication/INITIALISED',
  GET_ACCESS_TOKEN_COMPLETE = 'authentication/GET_ACCESS_TOKEN_COMPLETE',
  HANDLE_REDIRECT_COMPLETE = 'authentication/HANDLE_REDIRECT_COMPLETE',
  LOGOUT = 'authentication/LOGOUT',
  ERROR = 'authentication/ERROR',
}
