import * as React from 'react';
import { createTheme } from '@mui/material/styles';
// import type {} from '@mui/lab/themeAugmentation';
import { NavLink as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { LinkProps } from '@mui/material/Link';

// see https://mui.com/customization/theme-components/#adding-new-component-variants
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    navbar: true;
  }
}

// configure default <a> to use React Router Link: https://mui.com/guides/routing/
const LinkBehavior = React.forwardRef<
  any, // eslint-disable-line @typescript-eslint/no-explicit-any
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map 'href' (MUI) -> 'to' (react-router)
  return <RouterLink ref={ref} to={href} children {...other} />;
});

// property) React.RefAttributes<HTMLAnchorElement>.ref?: React.Ref<HTMLAnchorElement> | undefined

const srmLight = createTheme({
  palette: {
    // custom, from the previous portal.
    // tones added with adobe color picker, monochromatic
    mode: 'light',
    primary: {
      light: '#00A5B8',
      main: '#005f6a',
      dark: '#003238',
    },
    secondary: {
      light: '#FC8F44',
      main: '#f7634e',
      dark: '#FC44B2',
    },
    // leave the other colors as the MUI default, see https://mui.com/customization/palette/
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

// Adding variants: https://mui.com/customization/theme-components/
srmLight.components = {
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
    },
  },
  MuiButton: {
    // default action button is now not text anymore, but contained
    defaultProps: {
      variant: 'contained',
    },
    // variants: [
    //   {
    //     props: { variant: 'navbar' },
    //     style: {
    //       border: `2px dashed`,
    //       color: srmLight.palette.secondary.main,
    //       fontSize: 18,
    //     },
    //   },
    // ],
  },
};

export { srmLight };
