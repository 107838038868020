import { User } from '@auth0/auth0-spa-js';
import { IAuthState } from './types';
import { actions } from './config-options';

type Action =
  | { type: actions.LOGIN_POPUP_STARTED }
  | {
      type:
        | actions.INITIALISED
        | actions.LOGIN_POPUP_COMPLETE
        | actions.GET_ACCESS_TOKEN_COMPLETE
        | actions.HANDLE_REDIRECT_COMPLETE;
      user?: User;
    }
  | { type: actions.LOGOUT }
  | { type: actions.ERROR; error: Error };

export const reducer = (state: IAuthState, action: Action): IAuthState => {
  switch (action.type) {
    case actions.LOGIN_POPUP_STARTED:
      return { ...state, isLoading: true };

    case actions.LOGIN_POPUP_COMPLETE:
    case actions.INITIALISED:
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        isLoading: false,
        error: undefined,
      };

    case actions.HANDLE_REDIRECT_COMPLETE:
    case actions.GET_ACCESS_TOKEN_COMPLETE:
      if (state.user?.updated_at === action.user?.updated_at) {
        return state;
      }
      return { ...state, isAuthenticated: !!action.user, user: action.user };

    case actions.LOGOUT:
      return { ...state, isAuthenticated: false, user: undefined };

    case actions.ERROR:
      return { ...state, isLoading: false, error: action.error };
  }
};
