import { ThemeProvider, StyledEngineProvider, Grid } from '@mui/material';
import { useAuth } from '@srm-pp/authentication';
import { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { srmLight } from './theme';

// Set up date-time-picker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// https://mui.com/components/typography/
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const ProtectedApp = lazy(() => import('./protected-app'));
const UnauthenticatedApp = lazy(() => import('./unauthenticated-app'));

export function App() {
  const { isAuthenticated, isLoading } = useAuth();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <Suspense fallback={null}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={srmLight}>
              <Grid
                container
                display="flex"
                height="100vh"
                width="100%"
                direction="column"
                alignItems="center"
                flexWrap="nowrap"
                id="main-wrapper"
              >
                {!isAuthenticated && !isLoading ? (
                  <UnauthenticatedApp />
                ) : (
                  <ProtectedApp />
                )}
              </Grid>
            </ThemeProvider>
          </StyledEngineProvider>
        </Suspense>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
