import { User } from '@auth0/auth0-spa-js';

export interface IAuthState<TUser extends User = User> {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: TUser; //auth0-specific
}

export const initialAuthState: IAuthState = {
  isAuthenticated: false,
  isLoading: true,
};